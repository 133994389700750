// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-asesoria-js": () => import("./../../../src/templates/asesoria.js" /* webpackChunkName: "component---src-templates-asesoria-js" */),
  "component---src-templates-asesorias-js": () => import("./../../../src/templates/asesorias.js" /* webpackChunkName: "component---src-templates-asesorias-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-blog-new-js": () => import("./../../../src/templates/blog-new.js" /* webpackChunkName: "component---src-templates-blog-new-js" */),
  "component---src-templates-buscador-blog-js": () => import("./../../../src/templates/buscador-blog.js" /* webpackChunkName: "component---src-templates-buscador-blog-js" */),
  "component---src-templates-category-all-js": () => import("./../../../src/templates/category-all.js" /* webpackChunkName: "component---src-templates-category-all-js" */),
  "component---src-templates-error-404-js": () => import("./../../../src/templates/error-404.js" /* webpackChunkName: "component---src-templates-error-404-js" */),
  "component---src-templates-glossary-js": () => import("./../../../src/templates/glossary.js" /* webpackChunkName: "component---src-templates-glossary-js" */),
  "component---src-templates-integration-js": () => import("./../../../src/templates/integration.js" /* webpackChunkName: "component---src-templates-integration-js" */),
  "component---src-templates-integration-page-js": () => import("./../../../src/templates/integration-page.js" /* webpackChunkName: "component---src-templates-integration-page-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

