
import fetch from "isomorphic-fetch";
import { ApolloProvider } from 'react-apollo';
import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import React from "react";

export const wrapRootElement = ({ element }) => {
    // Register a new Apollo client.
    const client = new ApolloClient({
        // fetch,
        // Change this to your GraphQL endpoint.
      link: new HttpLink({
         uri: 'https://www.getbillage.com/wp/graphql',
         fetch,
       }),
       cache: new InMemoryCache()
    });

    // Wrap the element.
    return <ApolloProvider client={client}>{element}</ApolloProvider>;
};
