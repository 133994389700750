module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"production":{"allow404Images":true},"url":"https://www.getbillage.com/wp/graphql","useACF":true,"schema":{"timeout":120000}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
