/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
//import "./css/wp-mediaelement.min.css";

// import "./static/css/jquery-ui.min.css";
//import "./css/avia-merged-styles-7257ac610880942b170bcb41a047b473---6075c17c975c6.css";
import "./static/css/bootstrap.min.css";
import "./static/css/owl.carousel.min.css";
import "./static/css/owl.theme.default.min.css";
import "./static/fonts/gelion/stylesheet.css";
import "./static/css/custom.css";
import "./static/css/responsive.css";
import "./static/css/font-awesome.css";

var registerServiceWorker = () => true
const isSafari = window.safari !== undefined;
const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
// Do Firefox-related activities
if (isSafari || isFirefox) {
  console.log("firefox/safari");
  registerServiceWorker = () => false
}
export { registerServiceWorker };
export const onServiceWorkerUpdateReady = () => {
  // console.log("Entro sw");
  // const answer = window.confirm(
  //   `This application has been updated. ` +
  //     `Reload to display the latest version?`
  // )
  // if (answer === true) {
  window.location.reload()
  // }
}

// Wraps the entire Gatsby app with Apollo.
export { wrapRootElement } from './src/apollo/wrapRootElement';
